/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
	/* font-family: pacifico, sans-serif; */
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.Page_results,
.InnerPage{
	.banner{
		height: 66vh;
	}
	.down{
		display: none !important;
	}
	.PopIn{
		opacity: 1 !important;
		@include transform(scale(1) !important);	
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}


.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}

.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}
.FormFormat,
.mainContent{
	padding: 50px 10%;
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #c2a544 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
				margin-bottom: 0;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
		padding-bottom: 0;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 20px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 20px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 23%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 
/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: calc(100vh - 20px);
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1);
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}


/*** Page ***/

/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}


/*** Simple Menu CSS ***/
.MenuImage{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 150px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}
.LevelOneMenu{
	position: relative;
	display: block;
	text-align: center;
	font-size: 0.8em;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	*{
		font-size: 1em;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	li,ul{
		margin: 0px;
		padding: 0px;
	}
}
.LevelOneMenuItem.hasChildren:before{
		content: '';
		display: block;
		position: absolute;
		bottom: 0px;
		left: 50%;
		display: none;
		border: 15px solid transparent;
		@include transform(translateX(-50%) translateY(100%));

}
.LevelOneMenuItem.hasChildren:hover:before{
	display: block !important;
}
.LevelOneMenuItem{
	display: inline-block;
	position: relative;
	vertical-align: middle;

	text-align: center;
	min-width: 150px;

	&:hover:before{
		display: block;
	}
	&:hover{
		.LevelTwoMenu{
			display: block;
		}
	}
}



.LevelOneMenuItem-1{
	background-color: #176db7;
	.LevelTwoMenu{background-color: #176db7;}
	&:before{border-top-color: #176db7 !important;}
}
.LevelOneMenuItem-2{
	background-color: #4a7fc1;
	.LevelTwoMenu{background-color: #4a7fc1;}
	&:before{border-top-color: #4a7fc1 !important;}
}
.LevelOneMenuItem-3{
	background-color: #29a3dc;
	.LevelTwoMenu{background-color: #29a3dc;}
	&:before{border-top-color: #29a3dc !important;}
}
.LevelOneMenuItem-0{
	background-color: #4a7fc1;
	.LevelTwoMenu{background-color: #4a7fc1;}
	&:before{border-top-color: #4a7fc1 !important;}
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 10px;
}
.LevelTwoMenu{

	min-width: 550px;
	min-height: 150px;
    padding-left: 170px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 10px !important;
	position: absolute;
	display: none;
	left: 50%;
	bottom: -20px;
	@include transform(translateX(-50%) translateY(100%));
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 20px;
		top: 0px;
		left: 0px;
		@include transform( translateY(-100%));
	}
}
.LevelTwoMenuItem{  
	display: block;
	position: relative;
	width: 50%;
	float: left;
	padding: 5px !important;
	@include transition($t1);
	&:before{
		content: '';
		height: 15px;
		background-color: #fff;
		display: block;
		width: 1px;
		position: absolute;
		opacity: 0;
		left: 0px;
		top: 50%;
		@include transform(rotate(0deg) translateY(-50%));
		@include transition($t1);
	}
	&:hover:before{
		opacity: 1;
		left: 10px;
		@include transform(rotate(20deg) translateY(-50%));
	}
	&:hover{
		padding-left: 25px !important;
	}
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	float: left;
	color: #fff;
	text-transform: uppercase;
	@include transition($t1);
	background-color: rgba(0,0,0, 0);
	&:hover{
	}
}

.LevelTwoMenuLink{
	text-align: left;
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
	border-bottom: 1px solid #fff;
	color: #fff;
}

.LevelOneMenuLink:after {
    content: '';
    display: none;
    background-color: #000;
    height: 50%;
    width: 2px;
    right: 0px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
.LevelOneMenu .LevelOneMenuItem:last-of-type .LevelOneMenuLink:after{
	display: none !important;
}

.WideMenu{
	position: static;
	.LevelOneMenuItem{
		position: static;
	}
	.LevelTwoMenu {
    	width: 100%;
    	padding: 20px 15%;
	}
	.LevelTwoMenuItem{
		width: 25%;
		display: block;
		float: left;
		clear: none;
	}
}
/*** Simple Menu CSS ***/

.searchBlackout{
	position: fixed;
	top: 0px; 
	left: 0px;
	width: 100%;
	display: none;
	height: 100%;
	z-index: 99999;
	background-color: rgba(0,0,0,0.6);
}
.SearchContainer{
    display: none;
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 240px;
    @include transform($center);
    background-color: $themeColor;
    padding: 7px;
    z-index: 9999999;

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    } 
 
    button{
	    background-color: $themeColor;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: $themeColor;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 



.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
	@include transform($center);
	display: none;
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}


#header{
	position: absolute;
	z-index: 9999;
	padding-top: 70px;
}

.homebtn img{
	height: 110px;
	margin-bottom: 20px;
}
#Home2{
	padding: 100px 20px;
}

/* -------------------- General Site layouts -------------------- **/

.search{
	z-index:9;
	position: absolute;
	top: 30px;
	right: 30px;
	cursor: pointer;
	height: 30px;
	@include transition($t1);
	@include transform(scale(1));
	&:hover{
		@include transform(scale(1.2));
	}
}


/* -------------------- Site Build -------------------- **/
.LogoSlide{
	padding: 5%;
}
.LogoSlideInner{
	height: 120px;
}
.LogoSlideImage{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 120px;
	height: 120px;
	@include transform($center);
	@include border-radius(50%);
	background-position: center;
	background-image: url(../images/bg.jpg);
	background-size: contain;
	overflow: hidden;
	background-repeat: no-repeat;
	@include box-shadow(0px 0px 10px 0px rgba(0,0,0, 0.4));
}
/* -------------------- Site Build -------------------- **/




@keyframes dotOne {
   0%   {color: #fff;}
   100% {color: #4a7fc1;}
}
@keyframes dotTwo {
   0%   {color: #fff;}
   100% {color: #4a7fc1;}
}
@keyframes dotThree {
   0%   {color: #fff;}
   100% {color: #4a7fc1;}
}
.dot-1{
    font-size: 2em;
   animation: dotOne 200ms linear 0ms infinite alternate;
}
.dot-2{
    font-size: 2em;
   animation: dotTwo 200ms linear 100ms infinite alternate;
}
.dot-3{
    font-size: 2em;
   animation: dotThree 200ms linear 200ms infinite alternate;
}
.pace {
 -webkit-pointer-events: none;
 pointer-events: none;
 -webkit-user-select: none;
 -moz-user-select: none;
 user-select: none;
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 z-index: 999999999;
 -webkit-transform: translate3d(0, -50px, 0);
 -ms-transform: translate3d(0, -50px, 0);
 transform: translate3d(0, -50px, 0);
 -webkit-transition: -webkit-transform .5s ease-out;
 -ms-transition: -webkit-transform .5s ease-out;
 transition: transform .5s ease-out;
}
.pace.pace-active {
 -webkit-transform: translate3d(0, 0, 0);
 -ms-transform: translate3d(0, 0, 0);
 transform: translate3d(0, 0, 0);
}
.pace .pace-progress {
 display: block;
 position: fixed;
 z-index: 999999999;
 top: 0;
 right: 100%;
 width: 100%;
 height: 3px;
 background: #4a7fc1;
 pointer-events: none;
}
.loadingSection{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #2b2b2b;
  z-index: 99999999999;
   text-align: center;
}
.centerLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    img{width: 30px;}
    @include transform($center);
}
.loadingText{
    color: #efefef;
   text-transform: uppercase;
   font-size: 0.5em;
   letter-spacing: 4px;
   font-weight: bold;
   padding: 10px;
}
.Loading{
    overflow: hidden !important;
    height: 100vh;
    .banner{
        opacity: 0 !important;
    }
}





/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: transparent;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;

					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
					i { 
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					font-size: 1.8em;
				}	
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			color: #6a6c71;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
			position: relative;
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid #5d6161;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color: #5d6161;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color: #5d6161;
					}

				}
				z-index: 1;
				&.hasEvent:hover {
				z-index: 9990;
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}
		.hasEvent {
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
			background-image: none !important;
		}
		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}
	} 
	.calendar-day:hover, .selected {
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: #475f79 ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #1362a4;
    	display: inline-block;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}



.calendar-widget-table thead tr th{
	    background-color: $themeColor !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: $themeColor !important;
	*{
	    color: #fff !important;
	}
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}

.error {
    display: inline-block;
    border: 1px solid #da8486;
    margin: 10px 0px 20px 0px;
    padding: 5px 20px;
    font-size: 1em;
    color: #da8486;
    background-color: #ffdfdf;
}
.calendar-widget-table{
	thead *{
		color: #fff;
	}
}
.calendar-widget-table tbody .hasEvent{
	background-color: rgba(234, 234, 234, 0.44) !important;
}
/*************/
/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #333233;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: rgba(255,255,255,1);
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: rgba(255,255,255,1);
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: rgba(0,0,0,0.3);
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: rgba(255,255,255,1);
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: rgba(255,255,255,1);
}







.mainContent table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    max-width: 100% !important;
    width: 100% !important;
    a{
    	word-break: break-all;
    }
    }
.mainContenttable th,
.mainContent table td{
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
    padding: 10px;
}

.mainContenttable th{
	background-color: #efececd1;
}


.FormFormat table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    max-width: 100% !important;
    width: 100% !important;
    a{
    	word-break: break-all;
    }
    }
.FormFormat th,
.FormFormat table td{
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
    padding: 10px;
}

.FormFormat th{
	background-color: #efececd1;
}

.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
		color: #fff;
	}
}

/*************/
/*************/


#Home1{
	border-top: 20px solid #176db7;
	padding-left: 33%;
}

.WelcomeImage{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 33%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.WelcomeContainer{
	text-align: center;
	padding: 80px 8%;
}
.TitleOne{
	font-weight: bold;
	font-size: 2.7em;
	text-transform: uppercase;
	color: #176db7;
}
.TitleTwo{
	text-transform: uppercase;
	font-size: 1.7em;
	font-weight: lighter;
	color: #4ca3dc;
	margin-bottom: 50px;
}
.TextSection{
	font-weight: lighter;
	line-height: 1.3em;
	margin-bottom: 50px;
}
.ReadMore{
	display: inline-block;
	padding: 5px 30px 5px 10px;
	font-size: 0.8em;
	text-transform: uppercase;
	@include transition($t1);
	position: relative;
	border: 1px solid #2274ba;
	color: #818485;
	i{
		@include transition($t1);
		position: absolute;
		right: 10px;
		top: 50%;
		font-size: 1.6em;
		color: #29a3dc;
		@include transform(translateY(-50%));
	}
	&:hover{
		background-color: #2274ba;
		color: #fff;
		*{color: #fff;}
	}
}

.QuickLinkContainer{
	*{
		color: #fff;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.Quicklink{
	text-align: center;
	min-height: 200px;
}
.Quicklink-1{background-color: #4a7fc1;}
.Quicklink-2{background-color: #29a3dc;}
.Quicklink-3{background-color: #367cc0;}
.Quicklink-4{background-color: #306fb7;}


.GradImage{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 33%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.SlideImg{
	padding-bottom: 55%;
	margin-bottom: 20px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.sliderTitle{
	color: #176db7;
	padding-bottom: 20px;
	font-size: 1.7em;
}
.grad{
	position: absolute;
	top: 0px;
	left: 0px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.67+0,1+84,1+100 */
	background: -moz-linear-gradient(left,  rgba(255,255,255,0.67) 0%, rgba(255,255,255,1) 84%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(255,255,255,0.67) 0%,rgba(255,255,255,1) 84%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(255,255,255,0.67) 0%,rgba(255,255,255,1) 84%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	height: 100%;
	width: 100%;
}


.DownloadTitle{
	color: #176db7;
}
.DownloadHover{
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 10px 20px;
	text-align: center;
	width: 100%;
	height: 100%;
	background-color: #29a3dc;
	opacity: 0;
	@include transform(translateY(-50%) translateX(-100%));
}


.Download{
	overflow: hidden;
	display: block;
	position: relative;
	padding: 10px 20px;
	margin-bottom: 10px;
	text-transform: uppercase;
	background-color: #e3e3e3;
	@include transition($t1);
	*{@include transition($t1);}
	&:hover .DownloadHover{
	opacity: 1;
		@include transform($center );
	}
}

.eventsContainer,
.NewsletterSection{
	padding: 0px 5%;
}


#Home3{
	padding: 100px 20px;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	.TitleOne,
	.TitleTwo{
		color: #fff;
		text-align: center;
	}
	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}
	.inner{
		z-index: 1;
	}
}

.EventSection{
	padding: 3%;
	.EventDay{
		color: #fff;
		font-weight: bold;
		font-size: 2.8em;
	}
	.EventMonth{
		color: #fff;
		font-weight: lighter;
		font-size: 1.4em;
	}
	.EventSectionContent{
		min-height: 160px;
		margin-top: 15px;
		padding: 20px;
		background-color: rgba(32, 113, 158, 0.82);
		*{
			color: #fff;
		}
		.EventTitle{
			font-size: 1.5em;
		}
	}
	.EventBottom{
		position: absolute;
		left: 0px;
		bottom: 0px;
		width: 100%;
		color: #fff;
		*{color: #fff;}
		font-size: 0.8em;
		padding:5px 10px ;
		background-color: #4a7fc1; 
		img{
			height: 12px; 
			margin-right: 10px;
		}
	}
	.EventHover{
		position: absolute;
		bottom: 0px;
		text-align: center;
		left: 0px;
		width: 100%;
		overflow: hidden;
		padding: 0px 5%;
		height: 0%;
		opacity: 0;
		@include transition($t1);
		background-color: #4a7fc1; 
	}
	.EventBtn{
		min-width: 150px;
		text-transform: uppercase;
		display: inline-block;
		margin: 5px;
		color: #fff;
		font-size: 0.8em;
		padding: 5px 10px;
		border: 1px solid #fff;
		text-align: center;
		@include transition($t1);
		&:hover{
			color: #4a7fc1; 
			background-color: #fff;
		}
	}
	&:hover{
		.EventHover{
			opacity: 1;
			height: 100%;
		}
	}
}

.controls{
	position: absolute;
	right: 0px;
	top: 50%;
	@include transform(translateY(-50%));
}

.SliderOneNext{
	display: inline-block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../images/right.png);
	width: 35px;
	height: 35px;
	margin: 0px 5px;
	@include transition($t1);
	@include transform(scale(1));
	&:hover{
		@include transform(scale(1.2));
	}
	position: relative;
	a{
		position: absolute;
		height: 100%;
		top: 0px;
		width: 100%;
		left: 0px;
		height: 100%;
	}
}
.SliderOnePrev{
	display: inline-block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../images/left.png);
	width: 35px;
	height: 35px;
	margin: 0px 5px;
	@include transition($t1);
	@include transform(scale(1)); 
	&:hover{
		@include transform(scale(1.2));
	}
	position: relative;
	a{
		position: absolute;
		height: 100%;
		top: 0px;
		width: 100%;
		left: 0px;
		height: 100%;
	}
}

.bannerText{
	position: absolute;
	bottom:10%;
	z-index: 9;
	text-align: center;
	width: 100%;
	padding: 20px;
	*{
		color: #fff;
	}
	.partOne{
		text-transform: uppercase;
		font-size: 2em;
	}
	.partTwo{
		 font-family: pacifico, sans-serif;
   		 font-size: 4em;
   		 line-height: 1em;
	}
}

.group1{
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1;
	}
}

.down{
	position: absolute;
	top: -70px;
	display: inline-block;
	cursor: pointer;
	margin-left: -27px;
	@include transform(scale(1)) ;
	@include transition($t1);
	height: 55px;
	&:hover{
		@include transform(scale(1.2) );
	}
}

.quicklinkImg{
	height: 70px;
		@include transform(scale(1));
	@include transition($t1);
}
.QuicklinkTitle{
	padding-top: 10px;
	text-transform: uppercase;
}

.Quicklink:hover{
	.quicklinkImg{
		@include transform(scale(1.2));
	}
}


/******/
/******/
a.important{
	color: #c00;
	font-weight: bold;
}

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
}



.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
}

.tickertape a{
	color: #fff;
}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
}

.ticker{
	max-height: 46px;
	width: 100%;
	background-color: #29a3dc;
}

#LatestNews{
	position: absolute;
    left: 0px;
    padding-left: 10px;
	background-color: #29a3dc;
    height: 46px;
    color: #fff;
    z-index: 2;
    line-height: 45px;
    font-size: 1.6em;
    text-transform: uppercase;
    padding-right: 20px;
}
.TickerTitle{
	background-color: #29a3dc;
	text-align: center;
	color: #fff;
	line-height: 1em;
	padding: 10px 20px;
	font-size: 3em;
	font-family: pacifico, sans-serif;
}
.TwitterSection{
	padding: 5px;
}
.TwitterSectionInner{
	padding-bottom: 100%;
	text-align: center;
	background-size: cover;
	background-position: center;

	background-image: url(../images/bg.jpg);
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
	}
	&:hover{
		.TwitterSectionInnerInfo{
			height: 75%;
		}
	}
}
.TwitterSectionInnerInfo{
	padding: 20px;
	position: absolute;
	@include transition($t1);
	border-bottom: 5px solid #176db7;
height: 70%;
    font-sizE: 0.9em;
	bottom: 0px;
	left: 0px;
	width: 100%;
	*{color: #fff;}
	background-color: rgba(45,45,45,0.7);

}
.tweetTitle{
	text-align: center;
	position: absolute;
	top: 0px;
	display: inline-block;
	left: 50%;
	@include transform($center);
	background-color: #176db7;
	padding: 5px 20px;
	font-size: 0.8em;
	*{
		display: inline-block;
		vertical-align: middle;
	}
}
.TweetDate{
	width: 100%;
	position: absolute;
	bottom: 0px;
	padding: 10px;
	left: 0px;
	font-size: 0.7em;
}
#Footer{
	padding: 20px 5%;
	*{
		color: #81848b;
	}
	.dib{
		display: inline-block;
		vertical-align: middle;
	}
	.FooterLogo{
		height: 80px;
	}
}
.BlueText{
	color: #176db7 !important;
}
a{
	color: #176db7;
}

.fse{
	position: absolute;
	bottom: 20px;
	display: inline-block;
	right: 5%;
	color: #81848b !important;
	font-size: 0.7em;
	*{
		font-size: 1em;
	}
}
.FooterAddress{
	padding-left: 20px;
	margin-left: 20px;
	border-left: 2px solid #57b7e3;
	    font-size: 0.8em;
    line-height: 1.1em;
}
/******/
/******/
/******/

.FadeIn{
	opacity: 0;
}
.PopIn{
	opacity: 0;
	@include transform(scale(0.5));		
}
.ActiveIn{
	.PopIn{
		opacity: 1;
		@include transform(scale(1));	
	}
	.FadeIn{
		opacity: 1;
	}
}

.TwitterSection-2{
	.TwitterSectionInnerInfo{
		border-bottom: 5px solid #4a7fc1;
	}
	.tweetTitle{
		background-color: #4a7fc1;
	}
}
.TwitterSection-3{
	.TwitterSectionInnerInfo{
		border-bottom: 5px solid #29a3dc;
	}
	.tweetTitle{
		background-color: #29a3dc;
	}
}

.up{
	position: absolute;
	right: 5%;
	top: 20px;
	height: 45px;
	cursor: pointer;
	@include transition($t1);
	@include transform(scale(1));
	&:hover{
		@include transform(scale(1.2));
	}
}


#breadcrumbs{
	background-color: #ebebea;
	text-align: center;
	color: #818485;
	span{color: #818485;}
	padding: 10px 20px 10px calc(25% + 20px) ;
	*{
		text-transform: uppercase;
		font-size: 1em;
	}
	.here{
		color: #176db7;
	}
}

.MainContainer{
	z-index: 5;
}


.PageImg{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}

.UsefulLinks{
	padding: 30px;
	    background-color: #306fb7;
	color: #fff;
	*{
		color: #fff;
		font-size: 1em;
		text-transform: uppercase;
	}
	a{
		border-bottom: 1px solid #fff;
		padding: 5px 0px;

        @include transition($t1);
		&:before{
		    content: '';
		    height: 15px;
		    background-color: #fff;
		    display: block;
		    width: 1px;
		    position: absolute;
		    opacity: 0;
		    left: -10px;
		    top: 50%;
		    @include transform(rotate(0deg) translateY(-50%));
		    @include transition($t1);
		}
        &:hover:before{
            opacity: 1;
            left: 0px;
            @include transform(rotate(20deg) translateY(-50%));
        }
         &:hover{
         	padding-left: 15px;
          font-weight: bold;
        }
	}
}
.UsefulLinksTitle{
	padding-bottom: 15px;
	font-size: 1.8em;
}



.pageTitle{
	color: #176db7;
	font-size: 1.8em;
	text-transform: uppercase;
	padding-bottom: 20px;
} 

.SubTitle{
	color: #29a3dc !important;
	*{color: #29a3dc !important;}
}

.UpperCaseTitle{
	color: #176db7;
	*{color: #176db7;}
	text-transform: uppercase;
	font-weight: bold;
}


.FileUpload{
	padding: 10px 20px;
	color: #176dbb;
	background-color: #ebebea;
	margin-top: 10px;
	text-transform: uppercase;
	@include transition($t1);
	*{@include transition($t1);}
	span{
		color: #176dbb;
	}
	i{
		color: #176dbb;
		position: absolute;
		top: 50%;
		right: 20px;
		@include transform(translateY(-50%));
	}
	&:hover{
		background-color: #176dbb;
		color: #fff;
		*{color: #fff;}
	}
}


.GalNext .prev,
.GalPrev .next{
	color: #fff;
	font-weight: bold;
	font-size: 1.3em;	
}

.searchContainer{
	display: none;
	font-size: 0.8em;
	z-index: 8;
	position: absolute;
	top: 30px;
	height: 30px;
	padding: 4px 40px 6px 10px;
	right: 25px;
	background-color: #fff;
	border: none;
	@include border-radius(30px);
	*{
		height: 24px;
		border: none;
		display: inline-block;
		vertical-align: middle;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
}


.ContentSection{
	margin-top: 30px;
	border-top: 2px solid #629cce;
}
