
/* ------------  General Site Responsive ----------- */

@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}

	.LevelOneMenu{
		display: none;
	}
}

@media all and (max-width: 886px)  {
	#Footer{
		padding: 20px 10px;
		text-align: center;
		*{text-align: center;}
		.dib{
			display: block;
			width: 100%;
			padding: 10px;
			margin: 0px !important;
			border: none !important;
		}
		.fse{
			position: relative;
		    bottom: auto;
		    right: auto;
		    width: 100%;
		    text-align: center;
		}
	}
	.up{
		display: none;
	}
}
@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}

	.mainContent{
		padding: 50px 20px !important;
	}
	.col-3.clearMid{
		display: none !important;
	}
	#breadcrumbs {
	    padding: 10px 20px 10px 20px !important;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */

@media all and (max-width: 850px)  {
	#Home2{
		.col-6{
			width: 100%;
			clear: both;
		}
	}
	.TitleOne {
	    font-size: 1.7em;
	}
	.TitleTwo {
	    font-size: 1.2em;
	}
	.GradImage{
		display: none;
	}
	.eventsContainer,
	.NewsletterSection{
		padding: 0px 0px !important;
	}

}
@media all and (max-width:950px)  {
	.homebtn img {
	    height: 50px;
	}
	.bannerText {
		font-size: 0.7em;
	}
	.WelcomeImage{
		display: none;
	}
	#Home1{
		padding-left: 0px;
	}
	.EventSection{
		width: 100%;
	}
	#Home3 .inner{
		width: 600px;
	}
	.TwitterSection{
		width: 50%;
	}
}

@media all and (max-width:650px)  {
	.QuickLinkContainer .col-3{
		width: 50%;
	}
}
@media all and (max-width:500px)  {

	.TwitterSection{
		width: 100%;
	}
	.QuickLinkContainer .col-3{
		width: 100%;
	}
}



